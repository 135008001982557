<template lang="pug">
  v-container(fluid)
    v-row
      v-col(cols="12")
        v-text-field(
          label="Date"
          type="date"
          v-model="date"
          hide-details="auto"
          :error-messages="duckEggCollectionStorePostErrors.date"
        )
      v-col(cols="12" lg="6" md="6")
        v-autocomplete(
          label="Revenue Source"
          v-model="revenueSourceId"
          hide-details="auto"
          dense
          :items="duckEggCollectorList"
          item-value="id"
          item-text="code"
          :loading="duckEggCollectorGetting"
          :error-messages="duckEggCollectionStorePostErrors.revenue_source_id"
        )
          template(v-slot:item="{ item }")
            span.mr-2(
              :class="revenueSourceCodeColor(item.updated_at) + '--text'"
            ) {{ item.code }}
            small.blue-grey--text {{ item.description }}
      v-col(cols="12" lg="6" md="6")
        v-text-field(
          label="Good"
          type="number"
          v-model="good"
          hide-details="auto"
          dense
          :error-messages="duckEggCollectionStorePostErrors.good"
        )
      v-col(cols="12" lg="6" md="6")
        v-text-field(
          label="Reject"
          type="number"
          v-model="reject"
          hide-details="auto"
          dense
          :error-messages="duckEggCollectionStorePostErrors.reject"
        )
      v-col(cols="12" lg="6" md="6")
        v-text-field(
          label="Cracked"
          type="number"
          v-model="cracked"
          hide-details="auto"
          dense
          :error-messages="duckEggCollectionStorePostErrors.cracked"
        )
      v-col(cols="12" v-if="!editMode")
        v-btn(
          color="green"
          block
          small
          dark
          @click="store"
          :loading="duckEggCollectionStorePosting"
        )
          span save
      v-col(cols="12" v-else)
        v-row
          v-col(cols="6")
            v-btn(
              color="yellow darken-3"
              block
              small
              dark
              @click="update"
              :loading="duckEggCollectionStorePosting"
            )
              span update
          v-col(cols="6")
            v-btn(
              color="red"
              block
              small
              dark
              @click="editMode = false"
            )
              span cancel
</template>
<script>
import duckEggCollectionRepository from '@/repositories/duck-egg-collection.repository'
import revenueSourceRepository from '@/repositories/revenue-source.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [duckEggCollectorVars, duckEggCollectorVarNames] = requestVars({ identifier: 'duck-egg-collector' })
const [duckEggCollectionStoreVars, duckEggCollectionStoreVarNames] = requestVars({ identifier: 'duck-egg-collection-store', request: 'post', hasData: false })
const duckEggCollectorHandler = new VueRequestHandler(null, duckEggCollectorVarNames)
const duckEggCollectionStoreHandler = new VueRequestHandler(null, duckEggCollectionStoreVarNames)

const inputVars = () => ({
  id: null,
  revenueSourceId: null,
  good: null,
  reject: null,
  cracked: null,
  date: Window.getCurrentDate(),
})

export default {
  name: 'CreateDuckEggCollection',
  props: {
    defaultData: Object,
  },
  data () {
    return {
      ...inputVars(),
      ...duckEggCollectionStoreVars,
      ...duckEggCollectorVars,
    }
  },
  computed: {
    editMode: {
      get () {
        const { date, ...data } = this.defaultData
        return !this.$objectEmpty(data)
      },
      set (val) {
        if (val) return
        const { date } = this
        this.$emit('update:default-data', { date })
      },
    },
  },
  created () {
    this.getDuckEggCollectors()
    this.initWebsocket()
  },
  watch: {
    defaultData () {
      const input = inputVars()
      this.$populateInputData({ input })
    },
  },
  methods: {
    initWebsocket () {
      const { echo } = this.$store.state.websocket
      const dbStoreEvent = ({ data, model }) => {
        if (model === 'DuckEggCollection') {
          const index = this.duckEggCollectorList.findIndex(item => item.id === data.revenue_source_id)
          if (index < 0) return
          const temp = this.duckEggCollectorList.splice(index, 1)[0]
          this.duckEggCollectorList.push(temp)
        }
      }
      echo.private('database.event')
        .listen('DBStoreEvent', dbStoreEvent)
    },
    async store () {
      const confirm = await this.$confirm('Are you sure you want to save this date?')
      if (!confirm) return 
      const handler = duckEggCollectionStoreHandler
      const repository = duckEggCollectionRepository.store
      const { id, ...data } = this.$getInputData(inputVars())
      handler.setVue(this)
      handler.execute(repository, [data])
        .then(() => {
          const { date, ...defaults } = inputVars()
          this.$resetInputData(defaults)
        })
    },
    async update () {
      const confirm = await this.$confirm('Are you sure you want to update this date?')
      if (!confirm) return 
      const handler = duckEggCollectionStoreHandler
      const repository = duckEggCollectionRepository.update
      const { id, ...data } = this.$getInputData(inputVars())
      handler.setVue(this)
      handler.execute(repository, [id, data])
        .then(() => {
          const { date, ...defaults } = inputVars()
          this.$resetInputData(defaults)
        })
    },
    getDuckEggCollectors () {
      const handler = duckEggCollectorHandler
      const repository = revenueSourceRepository.duckEggCollectors
      handler.setVue(this)
      handler.execute(repository)
    },
    revenueSourceCodeColor (updated) {
      const updatedAt = updated.dateFormat({}, 'sv-SE')
      if (updatedAt === Window.getCurrentDate()) return 'primary'
      return 'blue-grey'
    },
  },
}
</script>